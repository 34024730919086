.meal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.meal img{
  width: 180px;
  height: 180px;
  object-fit: cover;
  align-self: center;
  
}

.meal h2 {
  margin: 0 0 0.7rem 0;
  font-size: 1.8rem;
}

.description {
  font-style: italic;
}

.price {
  margin-top: 0.8rem;
  font-weight: bold;
  color: #0755bb;
  font-size: 2rem;
}