.profile {
    margin: 0 auto;
    text-align: center;

  }
  
  .profile h1 {
    margin-top: 8rem;
    font-size: 5rem;
    color: rgb(55, 82, 230);
    letter-spacing: 0.2rem;
  }