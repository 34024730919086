.card {
    width: 25%;
    height: auto;
    margin: 20px auto;
    padding: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    background-color: white;
    transition: transform 0.4s;
  }

  .card:hover{
    transform: scale(1.15);
    
  }
  

