.input {
    display: flex;
    align-items: center;
    margin: 1rem auto;
  }
  
  .input label {
    font-weight: bold;
    font-size: 1.3rem;
    margin-right: 1rem;
  
  }
  
  .input input {
    width: 6rem;
    height: 18px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font: inherit;
    padding-left: 0.5rem;
    
  }