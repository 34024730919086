.header {
    width: 100%;
    height: 70px;
    margin: 0;
    padding-top: 10px;
    background: rgb(76,129,255);
    background: linear-gradient(285deg, rgba(76,129,255,0.6797312675070029) 0%, rgba(253,209,53,0.6769301470588236) 38%, rgba(250,15,15,0.640515581232493) 100%);
    box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.69);
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: sticky;
    
  }

  .header svg{
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: white;
  }
  
  .main_logo {
    width: 100px;
    height: 80px;
    object-fit: cover;
  }
  
  .header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
  }
  
  .header a {
    letter-spacing: 0.1rem;
    text-decoration: none;
    color: rgb(249, 2, 2);
    font-weight: bold;
  }
  
  .button_header{
    background-color: #e4002b;
    border:none;
    border-radius: 5px;
    margin-left: 50px;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    padding:13px;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 1s;
  }

  .button_header:hover{
    background-color: rgb(55, 82, 230);

  }

