@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap);

html{
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family:'Roboto', sans-serif;
}

.Card_card__3hpL0 {
    width: 25%;
    height: auto;
    margin: 20px auto;
    padding: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    background-color: white;
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
  }

  .Card_card__3hpL0:hover{
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    
  }
  


.AvailableMeals_meals__1PUuK {
  width: 90%;
  margin: 0 auto;
  -webkit-animation: AvailableMeals_meals-appear__1oY2m 1s ease-out forwards;
          animation: AvailableMeals_meals-appear__1oY2m 1s ease-out forwards;
}

.AvailableMeals_MealsLoading__1dlt7 {
  text-align: center;
  color: black;
  font-size: 1.4rem;

}

.AvailableMeals_MealsError__2vLPF{
  text-align: center;
  color:red;
}

.AvailableMeals_container1__3zMq0{
width: 95%;
margin: 0 auto;
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: wrap;
}



@-webkit-keyframes AvailableMeals_meals-appear__1oY2m {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}



@keyframes AvailableMeals_meals-appear__1oY2m {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.MealItem_meal__ho3NV {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.MealItem_meal__ho3NV img{
  width: 180px;
  height: 180px;
  object-fit: cover;
  align-self: center;
  
}

.MealItem_meal__ho3NV h2 {
  margin: 0 0 0.7rem 0;
  font-size: 1.8rem;
}

.MealItem_description__1LwSn {
  font-style: italic;
}

.MealItem_price__3T-hG {
  margin-top: 0.8rem;
  font-weight: bold;
  color: #0755bb;
  font-size: 2rem;
}
.MealItemForm_form__1d-X4 {
    text-align: right;
  }
  
  .MealItemForm_form__1d-X4 button {
    width: 100px;
    font-size: 1.7rem;
    cursor: pointer;
    background-color: #2a00fa;
    border: 1px solid #3600f9;
    color: white;
    padding: 0.8rem;
    border-radius: 20px;
    font-weight: bold;
  }
  
  .MealItemForm_form__1d-X4 button:hover,
  .MealItemForm_form__1d-X4 button:active {
    background-color: #e4002b;
    border-color: #e4002b;
   
  }
.Input_input__3EihZ {
    display: flex;
    align-items: center;
    margin: 1rem auto;
  }
  
  .Input_input__3EihZ label {
    font-weight: bold;
    font-size: 1.3rem;
    margin-right: 1rem;
  
  }
  
  .Input_input__3EihZ input {
    width: 6rem;
    height: 18px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font: inherit;
    padding-left: 0.5rem;
    
  }
.Search_general_section__iCmWf{
    width: 100%;
    height: 100px;
    margin-top: 7rem;
    text-align: center;
}

.Search_container_inputs__DaAsZ{
    display: flex;
    width: 95%;
    justify-content: center;
    margin: 0 auto;
}

.Search_container_inputs__DaAsZ label{
    font-size: 1.3rem;
    font-style: italic;
    font-family: inherit;
    margin-right: 30px;
    margin-left: 30px;
    align-self:center;
   
}

.Search_container_inputs__DaAsZ input{
    width: 40%;
    height: 30px;
    border-radius: 15px;
    border: none;
    /* border: 1px solid rgb(177, 175, 175); */
    padding: 1rem;
    outline-color: none; 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    
}

.Search_container_inputs__DaAsZ svg{
    width: 20px;
    height: 20px;
    align-self: center;
    margin-left: -35px;
}

.Search_general_section__iCmWf select{
    width: 200px;
    height: 40px;
    align-self: center;
    border: none;
    /* border: 1px solid rgb(177, 175, 175); */
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    padding: 0.5rem;
    outline-color: none;
    color:black;
}

select:hover{
    border:1px solid rgb(222, 222, 222);
}

input:hover{
    border:1px solid rgb(222, 222, 222);
}


input:focus{
    outline-color: rgb(216, 235, 253);
}

select:focus{
    outline-color: rgb(216, 235, 253);
}

.Header_header__2zutd{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    z-index: 10;
    padding: 20px;
    background-color: #ffff;
    box-shadow: 0px 12px 31px 0px rgba(0,0,0,0.35);

}

.Header_fondo_header__KVR2M{
    width: 100%;
    z-index: 0;
    overflow: hidden;
}

.Header_fondo_header__KVR2M img{
    width: 100%;
    object-fit: cover;
    -webkit-transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
            transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
}

.Header_img_logo__8ey5C{
    width: 120px;
    height: 90px;
    cursor: pointer;
    object-fit: cover;
    opacity: 0.9;
}

ul{
    display: flex;
    justify-content: space-between;
}

li{
    list-style: none;
    margin-right: 60px;
    cursor: pointer;
    font-family:'Roboto', sans-serif;
}

li a:hover {
    color: red;
}

li svg{
    width: 30px;
    height: 30px;
    
}

li svg:hover{
   color: red;
}

a {
    font-size:2.8rem;
    margin-left: 0px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    color: black;
}


.HeaderCartButton_button__tNKZs {
    cursor: pointer;
    font: inherit;
    border: none;
    background-color: #080808;
    color: white;
    padding: 0.60rem 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
    font-weight: bold;
  }
  
  .HeaderCartButton_button__tNKZs:hover,
  .HeaderCartButton_button__tNKZs:active {
    background-color: #e4002b;
  }
  
  .HeaderCartButton_span_cartButton__1D314{
    font-size: 1.5rem;
  }


  .HeaderCartButton_icon__2pRlB {
    
    margin-right: 1rem;
  }

  .HeaderCartButton_icon__2pRlB svg{
    width: 25px;
    height: 25px;
  }
  
  .HeaderCartButton_badge__2Ldo_ {
    background-color: #f0d01a;
    padding: 0.25rem 1rem;
    border-radius: 12px;
    margin-left: 1rem;
    font-weight: bold;
    font-size: 1.3rem;
  }
  
  .HeaderCartButton_button__tNKZs:hover .HeaderCartButton_badge__2Ldo_,
  .HeaderCartButton_button__tNKZs:active .HeaderCartButton_badge__2Ldo_ {
    background-color: #fae934;
  }
  
  .HeaderCartButton_bump__2vYAS {
    -webkit-animation: HeaderCartButton_bump__2vYAS 300ms ease-out;
            animation: HeaderCartButton_bump__2vYAS 300ms ease-out;
  }
  
  @-webkit-keyframes HeaderCartButton_bump__2vYAS {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    10% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    30% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    50% {
      -webkit-transform: scale(1.15);
              transform: scale(1.15);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes HeaderCartButton_bump__2vYAS {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    10% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    30% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    50% {
      -webkit-transform: scale(1.15);
              transform: scale(1.15);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
.CartItem_cart-item__3qkws {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #0755bb;
    padding: 1rem 0;
    margin: 1rem 0;
  }
  
  .CartItem_cart-item__3qkws h2 {
    margin: 0 0 1rem 0;
    color: #363636;
    font-size: 2rem;
  }

  .CartItem_cart-item__3qkws h4{
    max-width: 35rem;
    font-style: italic;
    font-family:'Roboto', sans-serif;
    font-size: 1rem;
    
  }
  
  .CartItem_summary__1jRBW {
    width: 14rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .CartItem_price__2jNb3 {
    font-weight: bold;
    color: #0755bb;
    font-size: 2rem;
  }
  
  .CartItem_amount__3jKyi {
    font-weight: bold;
    border: 1px solid #ccc;
    padding: 0.25rem 1rem;
    border-radius: 6px;
    color: #000000;
  }
  
  .CartItem_actions__12iY3 {
    display: flex;
    flex-direction: column;
  }
  
  @media (min-width: 768px) {
    .CartItem_actions__12iY3 {
      flex-direction: row;
    }
  }
  
  .CartItem_cart-item__3qkws button {
    font: inherit;
    font-weight: bold;
    font-size: 1.25rem;
    color: #0755bb;;
    border: 1px solid #0755bb;;
    width: 3rem;
    text-align: center;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
    margin-left: 1rem;
    margin: 0.25rem;
  }
  
  .CartItem_cart-item__3qkws button:hover,
  .CartItem_cart-item__3qkws button:active {
    background-color: #0755bb;
    color: white;
  }
.Cart_cart-items__3cUGA {
    list-style: none;
    margin: 0;
    padding: 10px;
    max-height: 20rem;
    overflow: scroll;
  }
   
  .Cart_total__2HiOu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #e4002b;
    font-weight: bold;
    font-size: 2rem;
    margin: 1rem 0;
    padding: 10px;
  }
  
  .Cart_actions__1wENW {
    text-align: right;
    margin-bottom: 30px;
    margin-right: 1rem;
    
  }
  
  .Cart_actions__1wENW button {
    font: inherit;
    cursor: pointer;
    border: 1px solid #e4002b;
    background-color: transparent;
    padding: 1rem 3rem;
    border-radius: 25px;
    margin-left: 2rem;
  }
  
  .Cart_actions__1wENW button:hover,
  .Cart_actions__1wENW button:active {
    background-color: #e4002b;
    color: white;
    font-weight: bold;
  }
  .Cart_actions__1wENW .Cart_button--alt__1cCAg {
    color: #e4002b;
   
  }
  
  .Cart_actions__1wENW .Cart_button__3zlbE {
    background-color: #003bd2;
    border: none;
    color: white;
    font-weight: bold;
  }
.Modal_Backdrop__2KkBT {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.Modal_modal__P_MCv {
  position: fixed;
  top: 15vh;
  left: 5%;
  width: 100%;
  height: auto;
  background-color: white;
  padding: 2rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  -webkit-animation: Modal_slide-down__mz9_z 300ms ease-out forwards;
          animation: Modal_slide-down__mz9_z 300ms ease-out forwards;
}


@media (min-width: 768px) {
  .Modal_modal__P_MCv {
    width: 60rem;
    left: calc(50% - 25rem);
  }
}

@-webkit-keyframes Modal_slide-down__mz9_z {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Modal_slide-down__mz9_z {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.Checkout_form__3Q_lS {
    width: 100%;
    margin: 0 auto;
    height: 140px;
    overflow: auto;
    padding: 0.5rem;
  }
  
  .Checkout_barra_espacio__1ugvD{
    width: 98%;
    margin: 0 auto;
    height: 10px;
    background-color: #e4002b;
  }

  .Checkout_control__2bxPJ {
    margin-bottom: 1.5rem;
  }

  
  .Checkout_control__2bxPJ label {
    font-size: 1.3rem;
    font-weight: bold;
    color:rgb(52, 52, 245);
    margin-bottom: 0.7rem;
    letter-spacing: 0.2rem;
    display: block;
  }
  
  .Checkout_control__2bxPJ input {
    font: inherit;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 28rem;
    height: 18px;
    max-width: 100%;
    padding: 0.5rem;
  }
  
  .Checkout_actions__1Q7lB {
    display: flex;
    justify-content: flex-end;
   
  }
  
  .Checkout_actions__1Q7lB button {
    font-size: 1rem;
    color: #000000;
    cursor: pointer;
    background-color: rgb(249, 173, 141);
    border: 1px solid rgb(249, 173, 141);
    border-radius: 25px;
    padding: 0.6rem 2.3rem; 
    margin-right: 10px;
    letter-spacing: 0.1rem;
  }
  
  .Checkout_actions__1Q7lB button:hover,
  .Checkout_actions__1Q7lB button:active {
    background-color: #ffe6dc;
  }
  
  .Checkout_actions__1Q7lB .Checkout_submit__1KNhw {
    border: 1px solid #5a1a01;
    background-color: #5a1a01;
    color: white;
  }
  
  .Checkout_actions__1Q7lB .Checkout_submit__1KNhw:hover,
  .Checkout_actions__1Q7lB .Checkout_submit__1KNhw:active {
    background-color: #7a2706;
  }
  
  .Checkout_invalid__aFiEv label {
    color: #ca3e51;
  }
  
  .Checkout_invalid__aFiEv input {
    border-color: #aa0b20;
    background-color: #ffeff1;
  }
  
  .Checkout_container_customer__3GWGx{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      padding:5px;
  }

  .Checkout_container_customer__3GWGx h3{
    font-size: 1.6rem;
  }

  .Checkout_customer_registrado__OPq_t{
      display: flex;
      flex-direction: column;
  }
.ProfileForm_form__3mnG7 {
    width: 95%;
    max-width: 25rem;
    margin: 0 auto;
  }
  
  .ProfileForm_control__um6jw {
    margin-bottom: 0.5rem;
  }
  
  .ProfileForm_control__um6jw label {
    font-weight: bold;
    margin-bottom: 0.8rem;
    color: #353336;
    display: block;
  }
  
  .ProfileForm_control__um6jw input {
    font-size: 1.5rem;
    display: block;
    font: inherit;
    width: 100%;
    height: 25px;
    border-radius: 4px;
    border: 1px solid rgb(53, 56, 247);
    padding: 0.6rem;
    background-color: #ffffff;
  }
  
  .ProfileForm_action__AEN1q {
    margin-top: 1.5rem;
  }
  
  .ProfileForm_action__AEN1q button {
    font: inherit;
    cursor: pointer;
    padding: 1rem 1.8rem;
    border-radius: 4px;
    background-color: #5165f9;
    color: white;
    border: none;
  }
  
  .ProfileForm_action__AEN1q button:hover {
    background-color: #5a73d7;
    border-color: #5a73d7;
  }
.UserProfile_profile__15Psw {
    margin: 0 auto;
    text-align: center;

  }
  
  .UserProfile_profile__15Psw h1 {
    margin-top: 8rem;
    font-size: 5rem;
    color: rgb(55, 82, 230);
    letter-spacing: 0.2rem;
  }
.MainNavigation_header__MWkcz {
    width: 100%;
    height: 70px;
    margin: 0;
    padding-top: 10px;
    background: rgb(76,129,255);
    background: linear-gradient(285deg, rgba(76,129,255,0.6797312675070029) 0%, rgba(253,209,53,0.6769301470588236) 38%, rgba(250,15,15,0.640515581232493) 100%);
    box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.69);
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    
  }

  .MainNavigation_header__MWkcz svg{
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: white;
  }
  
  .MainNavigation_main_logo__2Kgc0 {
    width: 100px;
    height: 80px;
    object-fit: cover;
  }
  
  .MainNavigation_header__MWkcz ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
  }
  
  .MainNavigation_header__MWkcz a {
    letter-spacing: 0.1rem;
    text-decoration: none;
    color: rgb(249, 2, 2);
    font-weight: bold;
  }
  
  .MainNavigation_button_header__1JuZa{
    background-color: #e4002b;
    border:none;
    border-radius: 5px;
    margin-left: 50px;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    padding:13px;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 1s;
  }

  .MainNavigation_button_header__1JuZa:hover{
    background-color: rgb(55, 82, 230);

  }


.AuthForm_auth__2vPO2 {
    margin: 50px auto 50px;
    width: 35%;
    min-width: 30%;
    height: auto;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    background: rgb(76,168,255);
    background: linear-gradient(90deg, rgba(76,168,255,0.5424763655462185) 0%, rgba(15,57,250,0.7833727240896359) 100%);
    box-shadow: 0px 12px 36px 0px rgba(0,0,0,0.69);
    text-align: center;
    color:white;
    font-weight: bold;
    font-size: 1.2rem;
    
}

.AuthForm_auth__2vPO2 svg{
    width: 25px;
    height: 25px;
    color: white;
    padding-top: 20px;

}

.AuthForm_auth__2vPO2 h1 {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    margin-bottom: 20px;
}

.AuthForm_control__2bwit {
    margin-bottom: 15px;
}

.AuthForm_control__2bwit svg{
    width: 10px;
    height: 10px;
    color: #e4002b;
    margin-left: -20px;
}

.AuthForm_control__2bwit label {
    display: block;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1rem;
    font-weight: bold;  
}

::-webkit-input-placeholder{
    padding-left: 3px;
    letter-spacing: 0.1rem;
    
}

:-ms-input-placeholder{
    padding-left: 3px;
    letter-spacing: 0.1rem;
    
}

::placeholder{
    padding-left: 3px;
    letter-spacing: 0.1rem;
    
}

.AuthForm_control__2bwit input {
    font: inherit;
    font-size: 1rem;
    background-color: #ffffff;
    color: #000000;
    border-radius: 5px;
    border: 1px solid rgb(101, 101, 101);
    width: 80%;
    margin: 0 auto;
    height: 20px;
    padding: 0.8rem;

}

.AuthForm_auth__2vPO2 .AuthForm_actions__21UQC {
    width: 80%;
    margin: 0 auto;
    background-color: transparent;
    height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AuthForm_auth__2vPO2 button svg{
    width: 5px;
    height: 5px;
}


.AuthForm_actions__21UQC button {
  background-color:#e4002b;
  border: none;
  border-radius: 5px;
  width: 95%;
  height: 45px;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  color: white;
  margin: 0 auto;
  cursor: pointer;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.AuthForm_actions__21UQC button:hover{
  background-color: #355cfa;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.AuthForm_toggle__JFQXD {
    font-size: 1.2rem;
    margin-top: 15px;
    margin-bottom: 30px;
    font-style: oblique;
    font-weight: bold;
    background-color: transparent;
    color: #e4002b;
    border: none;
    cursor: pointer;
}
  
 .AuthForm_toggle__JFQXD:hover {
    background-color: transparent;
    color: #1543fa;
    
}
.Print_general_container__3vtgj {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: auto;
    margin: 30px auto;
    text-align: center;
    background-color: rgb(236, 236, 254);
    padding: 30px;
    border: 1px solid rgba(128, 128, 128, 0.796);
    border-radius: 3px;
    
}

img {
    width: 80px;
    height: 80px;
    align-self: center;
}

.Print_item__pUXN3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Print_general_container__3vtgj h1{
    color: rgb(34, 34, 253);
}

.Print_general_container__3vtgj h2{
    text-align: center;
    color: red;
}

.Print_general_container__3vtgj p {
    font-size: 1.2rem;
    font-weight: bold;
    
}

.Print_general_container__3vtgj span {
    font-size: 1.1rem;
    max-width: 250px;
}




