.form {
    text-align: right;
  }
  
  .form button {
    width: 100px;
    font-size: 1.7rem;
    cursor: pointer;
    background-color: #2a00fa;
    border: 1px solid #3600f9;
    color: white;
    padding: 0.8rem;
    border-radius: 20px;
    font-weight: bold;
  }
  
  .form button:hover,
  .form button:active {
    background-color: #e4002b;
    border-color: #e4002b;
   
  }