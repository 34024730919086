.form {
    width: 100%;
    margin: 0 auto;
    height: 140px;
    overflow: auto;
    padding: 0.5rem;
  }
  
  .barra_espacio{
    width: 98%;
    margin: 0 auto;
    height: 10px;
    background-color: #e4002b;
  }

  .control {
    margin-bottom: 1.5rem;
  }

  
  .control label {
    font-size: 1.3rem;
    font-weight: bold;
    color:rgb(52, 52, 245);
    margin-bottom: 0.7rem;
    letter-spacing: 0.2rem;
    display: block;
  }
  
  .control input {
    font: inherit;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 28rem;
    height: 18px;
    max-width: 100%;
    padding: 0.5rem;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
   
  }
  
  .actions button {
    font-size: 1rem;
    color: #000000;
    cursor: pointer;
    background-color: rgb(249, 173, 141);
    border: 1px solid rgb(249, 173, 141);
    border-radius: 25px;
    padding: 0.6rem 2.3rem; 
    margin-right: 10px;
    letter-spacing: 0.1rem;
  }
  
  .actions button:hover,
  .actions button:active {
    background-color: #ffe6dc;
  }
  
  .actions .submit {
    border: 1px solid #5a1a01;
    background-color: #5a1a01;
    color: white;
  }
  
  .actions .submit:hover,
  .actions .submit:active {
    background-color: #7a2706;
  }
  
  .invalid label {
    color: #ca3e51;
  }
  
  .invalid input {
    border-color: #aa0b20;
    background-color: #ffeff1;
  }
  
  .container_customer{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      padding:5px;
  }

  .container_customer h3{
    font-size: 1.6rem;
  }

  .customer_registrado{
      display: flex;
      flex-direction: column;
  }