.general_container {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: auto;
    margin: 30px auto;
    text-align: center;
    background-color: rgb(236, 236, 254);
    padding: 30px;
    border: 1px solid rgba(128, 128, 128, 0.796);
    border-radius: 3px;
    
}

img {
    width: 80px;
    height: 80px;
    align-self: center;
}

.item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.general_container h1{
    color: rgb(34, 34, 253);
}

.general_container h2{
    text-align: center;
    color: red;
}

.general_container p {
    font-size: 1.2rem;
    font-weight: bold;
    
}

.general_container span {
    font-size: 1.1rem;
    max-width: 250px;
}



