.general_section{
    width: 100%;
    height: 100px;
    margin-top: 7rem;
    text-align: center;
}

.container_inputs{
    display: flex;
    width: 95%;
    justify-content: center;
    margin: 0 auto;
}

.container_inputs label{
    font-size: 1.3rem;
    font-style: italic;
    font-family: inherit;
    margin-right: 30px;
    margin-left: 30px;
    align-self:center;
   
}

.container_inputs input{
    width: 40%;
    height: 30px;
    border-radius: 15px;
    border: none;
    /* border: 1px solid rgb(177, 175, 175); */
    padding: 1rem;
    outline-color: none; 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    
}

.container_inputs svg{
    width: 20px;
    height: 20px;
    align-self: center;
    margin-left: -35px;
}

.general_section select{
    width: 200px;
    height: 40px;
    align-self: center;
    border: none;
    /* border: 1px solid rgb(177, 175, 175); */
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    padding: 0.5rem;
    outline-color: none;
    color:black;
}

select:hover{
    border:1px solid rgb(222, 222, 222);
}

input:hover{
    border:1px solid rgb(222, 222, 222);
}


input:focus{
    outline-color: rgb(216, 235, 253);
}

select:focus{
    outline-color: rgb(216, 235, 253);
}
