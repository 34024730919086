.meals {
  width: 90%;
  margin: 0 auto;
  animation: meals-appear 1s ease-out forwards;
}

.MealsLoading {
  text-align: center;
  color: black;
  font-size: 1.4rem;

}

.MealsError{
  text-align: center;
  color:red;
}

.container1{
width: 95%;
margin: 0 auto;
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: wrap;
}



@keyframes meals-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}