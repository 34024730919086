@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');


.header{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    z-index: 10;
    padding: 20px;
    background-color: #ffff;
    box-shadow: 0px 12px 31px 0px rgba(0,0,0,0.35);

}

.fondo_header{
    width: 100%;
    z-index: 0;
    overflow: hidden;
}

.fondo_header img{
    width: 100%;
    object-fit: cover;
    transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
}

.img_logo{
    width: 120px;
    height: 90px;
    cursor: pointer;
    object-fit: cover;
    opacity: 0.9;
}

ul{
    display: flex;
    justify-content: space-between;
}

li{
    list-style: none;
    margin-right: 60px;
    cursor: pointer;
    font-family:'Roboto', sans-serif;
}

li a:hover {
    color: red;
}

li svg{
    width: 30px;
    height: 30px;
    
}

li svg:hover{
   color: red;
}

a {
    font-size:2.8rem;
    margin-left: 0px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    color: black;
}

