.auth {
    margin: 50px auto 50px;
    width: 35%;
    min-width: 30%;
    height: auto;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    background: rgb(76,168,255);
    background: linear-gradient(90deg, rgba(76,168,255,0.5424763655462185) 0%, rgba(15,57,250,0.7833727240896359) 100%);
    box-shadow: 0px 12px 36px 0px rgba(0,0,0,0.69);
    text-align: center;
    color:white;
    font-weight: bold;
    font-size: 1.2rem;
    
}

.auth svg{
    width: 25px;
    height: 25px;
    color: white;
    padding-top: 20px;

}

.auth h1 {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    margin-bottom: 20px;
}

.control {
    margin-bottom: 15px;
}

.control svg{
    width: 10px;
    height: 10px;
    color: #e4002b;
    margin-left: -20px;
}

.control label {
    display: block;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1rem;
    font-weight: bold;  
}

::placeholder{
    padding-left: 3px;
    letter-spacing: 0.1rem;
    
}

.control input {
    font: inherit;
    font-size: 1rem;
    background-color: #ffffff;
    color: #000000;
    border-radius: 5px;
    border: 1px solid rgb(101, 101, 101);
    width: 80%;
    margin: 0 auto;
    height: 20px;
    padding: 0.8rem;

}

.auth .actions {
    width: 80%;
    margin: 0 auto;
    background-color: transparent;
    height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth button svg{
    width: 5px;
    height: 5px;
}


.actions button {
  background-color:#e4002b;
  border: none;
  border-radius: 5px;
  width: 95%;
  height: 45px;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  color: white;
  margin: 0 auto;
  cursor: pointer;
  transition: transform 0.4s;
}

.actions button:hover{
  background-color: #355cfa;
  transform: scale(1.1);
}

.toggle {
    font-size: 1.2rem;
    margin-top: 15px;
    margin-bottom: 30px;
    font-style: oblique;
    font-weight: bold;
    background-color: transparent;
    color: #e4002b;
    border: none;
    cursor: pointer;
}
  
 .toggle:hover {
    background-color: transparent;
    color: #1543fa;
    
}