.form {
    width: 95%;
    max-width: 25rem;
    margin: 0 auto;
  }
  
  .control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    font-weight: bold;
    margin-bottom: 0.8rem;
    color: #353336;
    display: block;
  }
  
  .control input {
    font-size: 1.5rem;
    display: block;
    font: inherit;
    width: 100%;
    height: 25px;
    border-radius: 4px;
    border: 1px solid rgb(53, 56, 247);
    padding: 0.6rem;
    background-color: #ffffff;
  }
  
  .action {
    margin-top: 1.5rem;
  }
  
  .action button {
    font: inherit;
    cursor: pointer;
    padding: 1rem 1.8rem;
    border-radius: 4px;
    background-color: #5165f9;
    color: white;
    border: none;
  }
  
  .action button:hover {
    background-color: #5a73d7;
    border-color: #5a73d7;
  }