.cart-items {
    list-style: none;
    margin: 0;
    padding: 10px;
    max-height: 20rem;
    overflow: scroll;
  }
   
  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #e4002b;
    font-weight: bold;
    font-size: 2rem;
    margin: 1rem 0;
    padding: 10px;
  }
  
  .actions {
    text-align: right;
    margin-bottom: 30px;
    margin-right: 1rem;
    
  }
  
  .actions button {
    font: inherit;
    cursor: pointer;
    border: 1px solid #e4002b;
    background-color: transparent;
    padding: 1rem 3rem;
    border-radius: 25px;
    margin-left: 2rem;
  }
  
  .actions button:hover,
  .actions button:active {
    background-color: #e4002b;
    color: white;
    font-weight: bold;
  }
  .actions .button--alt {
    color: #e4002b;
   
  }
  
  .actions .button {
    background-color: #003bd2;
    border: none;
    color: white;
    font-weight: bold;
  }