.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #0755bb;
    padding: 1rem 0;
    margin: 1rem 0;
  }
  
  .cart-item h2 {
    margin: 0 0 1rem 0;
    color: #363636;
    font-size: 2rem;
  }

  .cart-item h4{
    max-width: 35rem;
    font-style: italic;
    font-family:'Roboto', sans-serif;
    font-size: 1rem;
    
  }
  
  .summary {
    width: 14rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .price {
    font-weight: bold;
    color: #0755bb;
    font-size: 2rem;
  }
  
  .amount {
    font-weight: bold;
    border: 1px solid #ccc;
    padding: 0.25rem 1rem;
    border-radius: 6px;
    color: #000000;
  }
  
  .actions {
    display: flex;
    flex-direction: column;
  }
  
  @media (min-width: 768px) {
    .actions {
      flex-direction: row;
    }
  }
  
  .cart-item button {
    font: inherit;
    font-weight: bold;
    font-size: 1.25rem;
    color: #0755bb;;
    border: 1px solid #0755bb;;
    width: 3rem;
    text-align: center;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
    margin-left: 1rem;
    margin: 0.25rem;
  }
  
  .cart-item button:hover,
  .cart-item button:active {
    background-color: #0755bb;
    color: white;
  }